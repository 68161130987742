import * as React from 'react';

import { Routes } from '../../services/Routes';

const RoutesContext = React.createContext( Routes );

interface Props {
  children?: React.ReactNode;
}

export const RoutesProvider: React.FC<Props> = ({ children }) => (
  <RoutesContext.Provider value={Routes}>{children}</RoutesContext.Provider>
);

export const useRoutes = () => {
  const context = React.useContext( RoutesContext );

  if ( context === undefined ) {
    throw new Error( 'useRoutes can only be used inside RoutesProvider' );
  }
  return context;
};
