import generator from 'url-routes-generator';

const accountGenerator = generator.create({ baseURL: '/account/' });
const adminGenerator = generator.create({ baseURL: '/x/gbmc/' });
const blogGenerator = generator.create({ baseURL: 'https://blog.goldbelly.com' });
const checkoutGenerator = generator.create({ baseURL: '/buy/' });
const checkoutApiGenerator = generator.create({ baseURL: '/checkout' });
const cloudfrontGenerator = generator.create({ baseURL: 'https://stowage0.goldbelly.com' });
const eGiftAPIGenerator = generator.create({ baseURL: '/egiftings' });
const eGiftingGenerator = generator.create({ baseURL: '/buy/egifting' });
const eGiftRedemptionGenerator = generator.create({ baseURL: '/redeem/egifting' });
const helpGenerator = generator.create({ baseURL: 'https://help.goldbelly.com/hc/en-us' });
const imageGenerator = generator.create({ baseURL: 'https://img.goldbelly.com' });
const suborderAPIGenerator = generator.create({ baseURL: '/suborders' });

const removeLeadingSlash = ( path: string ) => {
  if ( !path.length ) return path;
  if ( path[0] === '/' ) return path.slice( 1 );
  return path;
};

export const Routes = {
  accessibility: () => generator.build( '/accessibility' )(),
  about: () => generator.build( '/about' )(),
  account: {
    root: () => accountGenerator.build( '' )(),
    favorites: () => accountGenerator.build( '/favorites' )(),
    journey: () => accountGenerator.build( '/journey' )(),
    orders: () => accountGenerator.build( '/orders' )(),
  },
  admin: {
    root: () => adminGenerator.build( '' )(),
    logOut: () => adminGenerator.build( '/session' )(),
    collection: ( id: number ) => adminGenerator.build( `/collections/${String( id )}` )(),
    collectionEdit: ( id: number ) => adminGenerator.build( `/collections/${String( id )}/edit` )(),
    merchantEdit: ( id: number ) => adminGenerator.build( `/merchants/${String( id )}/edit` )(),
    merchantProducts: ( id: number ) => adminGenerator.build( `/merchants/${String( id )}/products` )(),
    merchantProfileAndMedia: ( id: number ) =>
      adminGenerator.build( `/merchants/${String( id )}/profile_and_photos` )(),
    merchantShippingOptions: ( id: number ) =>
      adminGenerator.build( `/merchants/${String( id )}/shipping_rates` )(),
    merchantShippingSchedules: ( id: number ) =>
      adminGenerator.build( `/merchants/${String( id )}/schedules` )(),
    playlist: ( id: number ) => adminGenerator.build( `/tv/playlists/${String( id )}` )(),
    playlistEdit: ( id: number ) => adminGenerator.build( `/tv/playlists/${String( id )}/edit` )(),
    productAvailability: ( id: number ) =>
      adminGenerator.build( `/products/${String( id )}/availability` )(),
    productDescription: ( id: number ) =>
      adminGenerator.build( `/products/${String( id )}/description` )(),
    productMedia: ( id: number ) => adminGenerator.build( `/products/${String( id )}/product_images` )(),
    productOptions: ( id: number ) =>
      adminGenerator.build( `/products/${String( id )}/product_options` )(),
    productPantryItems: ( id: number ) =>
      adminGenerator.build( `/products/${String( id )}/product_pantry_items` )(),
    productPrice: ( id: number ) => adminGenerator.build( `/products/${String( id )}/price` )(),
    productQuantity: ( id: number ) => adminGenerator.build( `/products/${String( id )}/quantity` )(),
    refreshProductCache: ( id: number ) => adminGenerator.build( `/products/${String( id )}/touch` )(),
    shippingCalendar: ( id: number ) => adminGenerator.build( `/products/${String( id )}/calendar` )(),
    shippingOptions: ( id: number ) => adminGenerator.build( `/products/${String( id )}/shipping` )(),
    showcaseVideos: () => adminGenerator.build( '/showcase_videos' )(),
    videos: () => adminGenerator.build( '/tv/videos' )(),
    videoEdit: ( id: number ) => adminGenerator.build( `/tv/videos/${String( id )}/edit` )(),
    webCards: () => adminGenerator.build( '/web/cards' )(),
    webCustomerReviews: () => adminGenerator.build( '/web/customer_reviews' )(),
    webSocialPosts: () => adminGenerator.build( '/web/social_posts' )(),
    webTopCollections: () => adminGenerator.build( '/web/top_collections' )(),
  },
  api: {
    suborder: {
      cancel: ( id: number ) => suborderAPIGenerator.build( `/${String( id )}/cancellations` )(),
      delivery_date: ( id: number ) => suborderAPIGenerator.build( `/${String( id )}/delivery_date` )(),
      gift_message: ( id: number ) => suborderAPIGenerator.build( `/${String( id )}/gift_message` )(),
      reorder: ( id: number ) => suborderAPIGenerator.build( `/${String( id )}/reorder` )(),
    },
    eGift: {
      cancel: () => eGiftAPIGenerator.build( '/cancellation' )(),
      gift_message: () => eGiftAPIGenerator.build( '/gift_message' )(),
      purchaser: {
        confirmation: () => eGiftAPIGenerator.build( '/purchaser/confirmation' )(),
        gift: ( id?: string ) =>
          eGiftAPIGenerator.build( `/purchaser/egift/${id !== undefined ? id : ''}` )(),
      },
      redemption: {
        confirmation: ( hashID?: string ) =>
          eGiftAPIGenerator.build( `/recipient/confirmation/${hashID || ''}` )(),
        gift: ( hashID: string ) => eGiftAPIGenerator.build( `/recipient/egift/${hashID}` )(),
        order: ( hashID: string ) => eGiftAPIGenerator.build( `/recipient/orders/${hashID}` )(),
        shipping: ( hashID: string ) => eGiftAPIGenerator.build( `/recipient/shipping/${hashID}` )(),
        success: ( hashID: string ) => eGiftAPIGenerator.build( `/recipient/success/${hashID}` )(),
      },
    },
    checkout: {
      deliveryOptions: ( subOrderId: number ) =>
        checkoutApiGenerator.build( `/delivery_options/${subOrderId}` )(),
      express: {
        deliveryOptions: ( subOrderId: number ) =>
          checkoutApiGenerator.build( `/express_delivery_options/${subOrderId}` )(),
        deliveryDate: ( subOrderId: number ) =>
          checkoutApiGenerator.build( `/express_delivery_options_selected/${subOrderId}` )(),
        confirmation: () => checkoutApiGenerator.build( '/express_confirmations' )(),
      },
      newsletter: {
        subscribe: () => checkoutApiGenerator.build( '/newsletter_signups' )(),
      },
    },
  },
  auth: {
    apple: ( origin?: string ) =>
      generator.build( `/auth/apple` )({ origin: origin ?? window.location.pathname }),
    facebook: ( origin?: string ) =>
      generator.build( '/auth/facebook' )({ origin: origin ?? window.location.pathname }),
    google: ( origin?: string ) =>
      generator.build( '/auth/google_oauth2' )({ origin: origin ?? window.location.pathname }),
    logOut: () => generator.build( '/sign-out' )(),
  },
  blog: {
    root: () => blogGenerator.build( '' )(),
  },
  // FIXME: this should not be called "categories", these are actually restaurant/merchant categories
  // and it's not plural, it's singular because it points to just one
  categories: ( permalink: string ) => generator.build( '/categories/:permalink', { permalink })(),
  checkout: {
    root: () => checkoutGenerator.build( '/' )(),
    toggleMultipleAddresses: () => checkoutGenerator.build( '/shipping/toggle_multi' )(),
  },
  collection: ( permalink: string, params: { [key: string]: string } = {}) =>
    generator.build( '/collections/:permalink', { permalink })({ ...params }),
  customerInstructions: ( merchantPermalink: string, productPermalink: string ) =>
    generator.build( '/instructions/:merchantPermalink/:productPermalink/', {
      merchantPermalink: merchantPermalink.replace( /restaurants\//, '' ).replace( /\//, '' ),
      productPermalink,
    })(),
  corporate: () => generator.build( '/corporate-gifting' )(),
  eGifting: {
    landing: () => generator.build( '/e-gifts' )(),
    root: () => eGiftingGenerator.build( '/' )(),
    payment: () => eGiftingGenerator.build( '/payment' )(),
    success: () => eGiftingGenerator.build( '/success' )(),
  },
  eGifts: () => generator.build( '/e-gifts' )(),
  food: ( permalink: string, params: { [key: string]: string } = {}) =>
    generator.build( '/foods/:permalink', { permalink })({ ...params }),
  giftCards: {
    root: () => generator.build( '/restaurants/goldbelly-gift-cards' )(),
    giftCard: () => generator.build( '/restaurants/goldbelly-gift-cards/gift-card' )(),
  },
  giftCardBalance: () => generator.build( '/gift-card-balance' )(),
  help: {
    root: () => helpGenerator.build( '' )(),
    alternateShipping: () => helpGenerator.build( '/articles/360047581994' )(),
    contactArticle: () =>
      helpGenerator.build( '/articles/1260805024010-How-Do-I-Contact-Goldbelly' )(),
    goldbellyLive: () => helpGenerator.build( '/sections/1260801324129-GOLDBELLY-LIVE' )(),
    newRequest: () => helpGenerator.build( '/requests/new' )(),
    promoRewardsRules: () =>
      helpGenerator.build( '/articles/115003609894-Discount-Promo-Code-Rewards-Points-Rules' )(),
    returnPolicy: () => helpGenerator.build( '/articles/224269707' )(),
    shippingFAQ: () => helpGenerator.build( '/sections/1260800102469-SHIPPING-AN-ORDER' )(),
    shippingPolicy: () =>
      helpGenerator.build( '/articles/224334808-Goldbely-Shipping-Rules-Policies' )(),
  },
  holiday: ( permalink: string, params: { [key: string]: string } = {}) =>
    generator.build( '/holidays/:permalink', { permalink })({ ...params }),
  image: {
    consumer: ( path: string ) => imageGenerator.build( `/consumer/${removeLeadingSlash( path )}` )(),
    uploads: ( path: string ) => imageGenerator.build( `/uploads/${removeLeadingSlash( path )}` )(),
  },
  jobs: () => generator.build( '/jobs' )(),
  // merchant permalinks are inconsistently returned from the API with a leading slash
  // make sure to cleanup permalink other wise like generation fails
  merchant: ( permalink: string, params: { [key: string]: string } = {}) =>
    generator.build( '/restaurants/:permalink', {
      permalink: permalink?.replace( /restaurants\//, '' ).replace( /\//, '' ),
    })({
      ...params,
    }),
  nativeMobile: {
    download: () =>
      generator.build(
        'https://apps.apple.com/app/apple-store/id925911443?pt=95628824&ct=home-page-footer&mt=8'
      )(),
    cart: () => generator.build( 'goldbellyapp://cart' )(),
    rewards: () => generator.build( 'goldbellyapp://rewards' )(),
    home: () => generator.build( 'goldbellyapp://home' )(),
    orders: () => generator.build( 'goldbellyapp://orders' )(),
  },
  order: ( id: string ) => generator.build( `/orders/${id}` )(),
  press: () => generator.build( '/press' )(),
  privacy: () => generator.build( '/privacy-and-security' )(),
  product: (
    merchantPermalink: string,
    productPermalink: string,
    params?: { [key: string]: string }
  ) =>
    generator.build( '/restaurants/:merchantPermalink/:productPermalink', {
      merchantPermalink: merchantPermalink.replace( /restaurants\//, '' ).replace( /\//, '' ),
      productPermalink,
    })( params ),
  redemption: {
    eGift: {
      root: ( hashID: string ) => eGiftRedemptionGenerator.build( `/${hashID}` )(),
      confirmation: ( hashID: string ) => eGiftRedemptionGenerator.build( `/${hashID}/confirmation` )(),
      delivery: ( hashID: string ) => eGiftRedemptionGenerator.build( `/${hashID}/delivery` )(),
      exchange: ( hashID: string ) => eGiftRedemptionGenerator.build( `/${hashID}/exchange` )(),
      shipping: ( hashID: string ) => eGiftRedemptionGenerator.build( `/${hashID}/shipping` )(),
      success: ( hashID: string ) => eGiftRedemptionGenerator.build( `/${hashID}/success` )(),
    },
  },
  regions: () => generator.build( '/regions' )(),
  reviews: () => generator.build( '/reviews' )(),
  rewards: ( ref?: string ) => generator.build( '/rewards' )({ ref }),
  search: ( query: string, params: { [key: string]: string } = {}) =>
    generator.build( '/search' )({ ...params, q: query }),
  sellers: () => generator.build( '/sellers' )(),
  // FIXME: this is actually merchants for category; this should be adjusted to integrate new route:
  // /restaurants/categories/:category`
  shopByRestaurant: ( category: string ) => generator.build( `/restaurants/${category}` )(),
  shopByRestaurantCategory: ( category: string ) =>
    generator.build( `/restaurants/categories/${category}` )(),
  shopByRegion: {
    region: ( region: string ) =>
      generator.build( '/regions/:region', {
        region,
      })(),
    state: ( region: string, state: string ) =>
      generator.build( '/regions/:region/:state', {
        region,
        state,
      })(),
    city: ( region: string, state: string, city: string ) =>
      generator.build( '/regions/:region/:state/:city', {
        region,
        state,
        city,
      })(),
  },
  spotlight: ( permalink: string, params: { [key: string]: string } = {}) =>
    generator.build( '/spotlight/:permalink', { permalink })({ ...params }),
  suborderTracking: ( id: string ) => generator.build( `/trackings/${id}` )(),
  subscriptions: () => generator.build( '/restaurants/subscriptions' )(),
  terms: () => generator.build( '/terms-of-service' )(),
  tracking: () => generator.build( '/tracking' )(),
  tv: {
    root: ( query: { playlist?: string; video?: string } = {}) => generator.build( `/tv` )( query ),
    playlist: ( playlistSlug: string ) => generator.build( '/tv/:playlistSlug', { playlistSlug })(),
    video: ( slug: string, params: { [key: string]: string } = {}) =>
      generator.build( '/tv' )({ ...params, video: slug }),
  },
  video: {
    consumer: ( path: string ) =>
      cloudfrontGenerator.build( `/consumer/${removeLeadingSlash( path )}` )(),
  },
  videos: () => generator.build( '/videos' )(),
};
